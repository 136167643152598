/* ######################################################################################################################## */
/* FUNCTION (COOKIE NOTICE) ############################################################################################### */
/* ######################################################################################################################## */
function cookieNotice()
{
	if(jQuery('#cookie-notification').length && Cookies.get('cookie-notification') !== 'true')
	{
		jQuery('#cookie-notification').show();

		jQuery('.cookie_close').on('click', function(e)
		{
			e.preventDefault();
			Cookies.set('cookie-notification', 'true', { expires : 7 });
			jQuery('#cookie-notification').fadeOut(speed, ease);
		});
	}
	else
		jQuery('#cookie-notification').hide();
}
