/* ######################################################################################################################## */
/* FUNCTION (MODAL WINDOW) ################################################################################################ */
/* ######################################################################################################################## */
function modalWindow(speed, ease)
{
	$('.js-modal_trigger').on('click', function(e)
	{
		e.preventDefault();
		e.stopPropagation();

		var $this = $(this);
			idModal     = $this.attr('href');

		toggleModalwindow(idModal);
	});

	$(document)
		.on('click', function(e)
		{
			if($('.js-modal_wrapper.open').length > 0)
			{
				if($(e.target).closest('.js-modal_content').length === 0)
				{
					if($('.js-modal_wrapper.open').hasClass('ajax-product_wrapper'))
					{
						window.history.back();
						document.title = "{{ post.title|raw }}";
					}

					closeModalwindows();
				}
			}
		})
		.keyup(function(e)
		{
			if($('.js-modal_wrapper.open').length > 0)
			{
				if(e.keyCode == 27)
				{
					if($('.js-modal_wrapper.open').hasClass('ajax-product_wrapper'))
					{
						window.history.back();
						document.title = "{{ post.title|raw }}";
					}

					closeModalwindows();
				}
			}
		});
}

/* ######################################################################################################################## */
/* FUNCTION (TOGGLE MODAL WINDOW) ######################################################################################### */
/* ######################################################################################################################## */
function toggleModalwindow(idModal)
{
	var speed = '';
		ease  = '';

	document.body.style.overflow = "hidden";

	if($(idModal).hasClass('open'))
	{
		document.body.style.overflow = "";
		$(idModal).removeClass('open');

		// SLT Video hack
		$('video', '#modal-slt-video').remove();
	}
	else
	{
		$(idModal).addClass('open');
		document.body.style.overflow = "hidden";
	}

	checkModalHeight(idModal);

	$(window).bind('resizeEnd', function(){ checkModalHeight(idModal) });
}

/* ######################################################################################################################## */
/* FUNCTION (CLOSE MODAL WINDOW) ########################################################################################## */
/* ######################################################################################################################## */
function closeModalwindows()
{
	document.body.style.overflow = "";
	$('.js-modal_wrapper').removeClass('open');

	// SLT Video hack
	$('video', '#modal-slt-video').remove();
}

/* ######################################################################################################################## */
/* FUNCTION (CHECK MODAL HEIGHT) ########################################################################################## */
/* ######################################################################################################################## */
function checkModalHeight(idModal)
{
	var thisModal     = $(idModal);
		modalContent  = $('.js-modal_content', idModal);
		hModalContent = modalContent.outerHeight(true);
		hBody         = $(window).height();

	if(hModalContent >= hBody)
		thisModal.addClass('fixedHeight');
	else
		thisModal.removeClass('fixedHeight');
}
