/* ######################################################################################################################## */
/* FUNCTION (FORM VALIDATION) ############################################################################################# */
/* ######################################################################################################################## */
function formValidation(speed, ease)
{
	jQuery('form').each(function()
	{
		var jQthis = jQuery(this);

		if(jQthis.hasClass('js-browser-validation'))
			jQthis.attr('novalidate', 'novalidate');
	});

	jQuery('input, select, textarea').not('.js-input-novalidate').change(function()
	{
		var jQthis = jQuery(this);

		if(jQthis.parents('form').hasClass('js-browser-validation'))
			jQthis.validate();
	});

	jQuery('form').submit(function(e)
	{
		var jQthis = jQuery(this);

		jQthis.find('.input_submit').addClass('is-submitting');

		if(jQthis.hasClass('js-browser-validation'))
		{
			jQthis.find('input, select, textarea').validate();

			if(jQthis.find('.js-field.is-invalid').length)
			{
				e.preventDefault();

				jQthis.find('.js-alert.is-invalid').slideDown(speed, ease);

				setTimeout(function()
				{
					jQuery('.js-modal_wrapper').each(function()
					{
						var idModal = '#' + jQuery(this).attr('id');

						if(jQuery(idModal).hasClass('open'))
							document.body.style.overflow = "hidden";

						checkModalHeight(idModal);
					});
				}, speed);

				// jQuery('.is-invalid', jQthis).find('input, select, textarea').first().focus();

				var offsetTop = jQuery('.is-invalid', jQthis).find('input, select, textarea').first().parents('.field').offset().top;
				var headerH   = $('#header').outerHeight(true);

				jQuery('html, body').animate(
				{
					scrollTop : offsetTop - headerH
				}, speed * 2, ease, function()
				{
					jQuery('.is-invalid', jQthis).find('input, select, textarea').first().focus();
				});

				jQthis.find('.input_submit').removeClass('is-submitting');
			}
			else
			{
				jQthis.find('.js-alert.is-invalid').slideUp(speed, ease);

				$recaptcha = jQthis.find('.g-recaptcha');

				if($recaptcha.length)
				{
					e.preventDefault();

					var recaptchaId    = $recaptcha.data('recaptcha-id');
					currentRecaptchaId = recaptchaId;

					if(typeof grecaptcha === 'object')
					{
						grecaptcha.execute(recaptchaId);
					}
				}
				else
				{
					this.submit();
				}
			}
		}
	});
}

/* ######################################################################################################################## */
/* FUNCTION (VALIDATE FIELDS) ############################################################################################# */
/* ######################################################################################################################## */
jQuery.fn.validate = function()
{
	return jQuery(this).each(function()
	{
		var jQfield         = jQuery(this).closest('.js-field'),
			jQfieldFeedback = jQfield.find('.field-feedback span'),
			validity 		= jQuery(this).prop('validity');

		jQfield.removeClass('is-valid is-invalid');

		if(validity.valid === true)
		{
			jQfield.addClass('is-valid');
		}
		else if(validity.valid === false)
		{
			var validationMsg = jQfieldFeedback.data('validation-msg');

			if(!jQfieldFeedback.data('validation-msg'))
			{
				var message = 'There is a problem with the data inputted';

				if(validity.valueMissing === true)
					message = 'This is a required field';

				else if(validity.typeMismatch === true && jQuery(this).attr('type') === 'email')
					message = 'Please enter a valid email address';

				else if (validity.typeMismatch === true && jQuery(this).attr('type') === 'url')
					message = 'Please enter a valid website address';

				else if (validity.tooShort === true)
					message = 'Must be a minimum of ' + jQuery(this).attr('minlength') + ' characters';

				else if (validity.tooLong === true)
					message = 'Must be a maximum of ' + jQuery(this).attr('maxLength') + ' characters';

				jQfieldFeedback.html(message);
			}
			else
			{
				jQfieldFeedback.html(validationMsg);
			}

			jQfield.addClass('is-invalid');
		}
	});
};

/* ######################################################################################################################## */
/* FUNCTION (AJAX FORM SUBMITS) ########################################################################################### */
/* ######################################################################################################################## */
var ajaxSubmit = function($form)
{
	if($form instanceof jQuery !== true)
		$form = jQuery($form);

	$form.find('.js-btn-ajax').addClass('is-loading');

	jQuery('#alert').fadeOut(s.animSpeed, function()
	{
		jQuery('#alert').remove();
	});

	$.post(
	{
		url      : $form.attr('action'),
		data     : $form.serialize(),
		dataType : 'html',
		success  : function(response, status)
		{
			$form.find('.js-btn-ajax').removeClass('is-loading');

			var $html  = jQuery('<html></html>').append(jQuery(response));
			var $alert = $html.find('#alert');

			$alert.clone().css('opacity', 0).appendTo('body').animate(
			{
				opacity: 1
			}, s.animSpeed);

			if($alert.hasClass('alert-valid'))
			{
				$form[0].reset();
				$form.find('.js-field').removeClass('is-valid');
			}
			else
			{
				var $newForm = $html.find('#' + $form.attr('id'));

				$newForm.find('.js-field.is-invalid').each(function(i)
				{
					var $oldField = $form.find('.js-field:eq(' + i + ')');

					$oldField.find('.js-field-feedback span').text(jQuery(this).find('.js-field-feedback span').text());
					$oldField.addClass('is-invalid');
				});

				$form.find('.js-field.is-invalid:first').find('.js-field-control').focus();
			}
		}
	});
};

window.ajaxSubmit = ajaxSubmit;
