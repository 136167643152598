/* ######################################################################################################################## */
/* NO-JS / JS ############################################################################################################# */
/* ######################################################################################################################## */
document.querySelector('html').classList.remove('no-js');
document.querySelector('html').classList.add('js');

/* ######################################################################################################################## */
/* SENTRY ################################################################################################################# */
/* ######################################################################################################################## */
var trackErrors = true;

if(typeof Raven === 'object' && trackErrors === true)
	Raven.config('https://4df473eca4b74354bea35880cf757383@sentry.io/1476403').install();

/* ######################################################################################################################## */
/* GENERIC VARS ########################################################################################################### */
/* ######################################################################################################################## */
var speed = 300;
var	ease  = 'easeInOutQuart';

/* ######################################################################################################################## */
/* SCRIPTS (WINDOW ONLOAD) ################################################################################################ */
/* ######################################################################################################################## */
$(window).load(function()
{
	// Slick
	$('.slick').slick();
});

/* ######################################################################################################################## */
/* SCRIPTS (DOCUMENT READY) ############################################################################################### */
/* ######################################################################################################################## */
$(document).ready(function($)
{
	cookieNotice();                 // Cookie notice
	modalWindow(speed, ease);       // Modal window
	formValidation(speed, ease);    // Form validation
	stickyHeader();                 // Sticky header

	// Default anchor behaviour
	$('a[href="#"]').on('click', function(e)
	{
		e.preventDefault();
	});

	// Main navigation trigger for mobile
	$('.main-navigation_trigger').on('click', function(e)
	{
		$('html').toggleClass('js_main-navigation_open');
	});

	// SLT Video
	$('.js-slt_video').on('click', function()
	{
		$('.wrapper_video', '#modal-slt-video').html('<video width="560" height="315" controls><source src="' + $(this).attr('data-video') + '" type="video/mp4" /></video>' );
	});
});

/* ######################################################################################################################## */
/* SCRIPTS (WINDOW RESIZE) ################################################################################################ */
/* ######################################################################################################################## */
$(window).bind('resizeEnd', function()
{
});

// resizeEnd
$(window).resize(function()
{
	if(this.resizeTO)
		clearTimeout(this.resizeTO);

	this.resizeTO = setTimeout(function()
	{
		$(this).trigger('resizeEnd');
	}, 100);
});

/* ######################################################################################################################## */
/* SCRIPTS (WINDOW SCROLL) ################################################################################################ */
/* ######################################################################################################################## */
$(window).scroll(function()
{
});

/* ######################################################################################################################## */
/* IS IN VIEWPORT ######################################################################################################### */
/* ######################################################################################################################## */
$.fn.isInViewport = function()
{
	var elementTop     = $(this).offset().top;
	var elementBottom  = elementTop + $(this).outerHeight();
	var viewportTop    = $(window).scrollTop();
	var viewportBottom = viewportTop + $(window).height();

	return elementBottom > viewportTop && elementTop < viewportBottom;
};

/* ######################################################################################################################## */
/* SCROLL TO ELEM ######################################################################################################### */
/* ######################################################################################################################## */
function scrollToElem(elem, offset) {
    var y = elem.getBoundingClientRect().top + window.pageYOffset - offset;
    window.scrollTo({
        top : y,
        behavior : 'smooth'
    });
};

/* ######################################################################################################################## */
/* GOTO ELEM ############################################################################################################## */
/* ######################################################################################################################## */
$('.js-goto_elem').on('click', function(el)
{
	var $this = $(this);
	var elemId = $this.attr('data-elem');
	var headerHeight = document.querySelector('.header').offsetHeight;
	var stickyHeight = 0;

	if (typeof(document.querySelector('.js-sticky')) != 'undefined' && document.querySelector('.js-sticky') != null)
		stickyHeight = document.querySelector('.js-sticky').offsetHeight;

	scrollToElem(document.getElementById(elemId), (headerHeight + stickyHeight));
});
