var recaptchaSiteKey = document.getElementById('google-recaptcha-site-key').getAttribute('data-google-recaptcha-site-key');

function closest(e, t) {
	var c, a;
	for (["matches", "webkitMatchesSelector", "mozMatchesSelector", "msMatchesSelector", "oMatchesSelector"].some(function(e) {
			return "function" == typeof document.body[e] && (c = e, !0)
		}); e;) {
		if ((a = e.parentElement) && a[c](t)) return a;
		e = a
	}
	return null
}
if ("undefined" != typeof recaptchaSiteKey) var currentRecaptchaId, recaptchas = [],
	recaptchaCallback = function(e) {
		var t = closest(document.querySelectorAll('.g-recaptcha[data-recaptcha-id="' + currentRecaptchaId + '"]')[0], "form");
		t.classList.contains("js-ajax-form") ? t.dispatchEvent(new Event("recaptcha_complete")) : t.submit()
	},
	recaptchaOnloadCallback = function() {
		for (var e = document.querySelectorAll(".g-recaptcha"), t = 0; t < e.length; t++)
			for (var c = closest(e[t], "form").querySelectorAll("input, select, textarea, button"), a = 0; a < c.length; a++) c[a].addEventListener("focus", function() {
				var e = closest(this, "form");
				if (recaptcha = e.querySelectorAll(".g-recaptcha")[0], recaptcha.getAttribute("data-recaptcha-id")) return !1;
				var t = grecaptcha.render(recaptcha, {
					sitekey: recaptchaSiteKey,
					size: "invisible",
					callback: recaptchaCallback
				});
				recaptcha.setAttribute("data-recaptcha-id", t), recaptchas.push(t)
			}, !1)
	};
else console.log("ReCAPTCHA 'recaptchaSiteKey' is not defined.");
